<template>
  <div id="newsStoryContent">
    <p id="Text1">
      Minifigures,
    </p>
    <p>
      Alert status: Orange!  The Spider Queen has fled Avant Gardens and escaped to Block Yard!
    </p>
    <p>
      Deep in the large hidden caves behind the Spider Cave, the Spider Queen and her legion of Dark Spiderlings have been busy at work.  Beyond the fog of the Spider Cave, a dark, twisty tunnel has recently been unearthed. It leads past the back of the Paradox Research Facility to a wide, spacious cavern… a cavern which has been taken over by Dark Spiderlings!  Those tricky arachnids managed to repair the previously abandoned Rocket Launch Pad locked deep in the mines, allowing the Spider Queen to invade the defenseless Block Yard!
    </p>
    <p>
      <!-- picE9D7CB1F2BC9E50B3CBDA8C2CABCAF43.jpg -->
      <img src="@/assets/news-network/spider-queen.jpg" class="rounded">
    </p>
    <p>
      In the new “Power of the Nexus Force” update, new minifigures will be able to track and encounter the nefarious Spider Queen as part of a new quest line in Avant Gardens.  Knowing the danger that arachnid royalty presents new explorers, the Block Yard Property Guard will soon carry powerful Nexus Force Faction gear, which fresh recruits will be able to borrow should they decide to face the Spider Queen alone.
    </p>
    <p>
      If you have already joined a Faction and completed a lion’s share of Avant Gardens quests – never fear!  You will be able to take on the Spider Queen without re-completing any missions via a new Spider Queen Battle instance – just use the repaired Launch Pad in the Maelstrom Mines!  Make sure you bring her some tea and give her a stern talking to about her evil ways.  If she doesn’t listen, you may need to use a stronger method of communication –a piping hot smack down!  You’ll need to be quick on your feet if you want to dodge her ferocious Fireball, Waves of Fire, and Rain of Fire attacks!
    </p>
    <p>
      Remember that the Spider Queen Battle is tuned for players early in the game – she is a resident of Avant Gardens, after all.  Though her four-leggedness is not as tough as, say, Butterscorch, she is still mightier than several dozen of her fearsome children.  Advanced players will want to take her down a few times not only to harvest her Achievement, but also to feel the zen-like satisfaction of squashing a really, really big bug, and experience one of the most unique and exciting battles in LEGO Universe!
    </p>
    <p>
      If you have a minifigure that has landed in Avant Gardens before the Power of the Nexus Force releases, but has not completed all the missions there yet, we will complete these missions for you automatically to avoid any awkward overlapping.  You will find all of the Mission rewards waiting for you in your Mailbox and the appropriate amount of U-Score will be added to your Passport!
    </p>
    <p>
      If you start a new minifigure after the Power of the Nexus Force releases, you will notice many new changes to the Venture Explorer Spaceship and Avant Gardens zones.
    </p>
    <p>
      Discuss this article on the <router-link to="/messageboards/4073462">message boards</router-link>!
    </p>
  </div>
</template>
